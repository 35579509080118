import React from "react";
import StaticPageLayout from "../layouts/static-page-layout";
import UnderMaintenance from "../static-components/under-maint";

const ForBusinessPage = () => {
  return (
    <StaticPageLayout>
      <UnderMaintenance pageName="For Business" />
    </StaticPageLayout>
  );
};

export default ForBusinessPage;
