import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useAuth } from '../contexts/AuthContext';
import { supabaseService } from '../services/supabase-service';
import { encrypt } from '../utils/security';
import './indiv-signup.css';
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { supabase } from '../lib/supabase';
import MaskedInput from 'react-text-mask';

function IndivSignup() {
  const { user, signOut } = useAuth();
  const navigate = useNavigate();
  const [showSignupForm, setShowSignupForm] = useState(false);
  const [currentStep, setCurrentStep] = useState(1);
  const [completedSteps, setCompletedSteps] = useState([]);
  const [error, setError] = useState(null);
  const [formData, setFormData] = useState({
    personalInfo: {
      firstName: '',
      middleName: '',
      lastName: '',
      dateOfBirth: '',
      ssn: '',
      confirmSsn: '',
      email: user?.email || '',
      phoneNumber: ''
    },
    employmentInfo: {
      currentEmployer: '',
      salary: ''
    },
    contactInfo: {
      address: {
        streetAddress: '',
        city: '',
        state: '',
        zipCode: '',
        isMailingAddress: null
      }
    },
    hsaEligibility: {
      hasHDHP: null,
      hasOtherCoverage: null,
      hasMedicare: null,
      isDependent: null,
      isEligibleResident: null,
      isEligible: null,
      hasHraFsa: null,
      isUSTaxpayer: null,
      hasVABenefits: null,
      hasTRICARE: null
    }
  });
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [showDatePicker, setShowDatePicker] = useState(false);

  console.log('IndivSignup rendering');
  console.log('Current user:', user);

  useEffect(() => {
    const checkAuth = async () => {
      console.log('checkAuth running');
      if (!user) {
        console.log('No user found, redirecting to login');
        navigate('/login');
        return;
      }

      try {
        console.log('Checking Supabase for user profile');
        const { data, error } = await supabaseService.getIndividualProfile(user.id);

        console.log('Profile check response:', { data, error });

        if (error) {
          throw error;
        }

        if (data) {
          console.log('User profile found, redirecting to dashboard');
          navigate('/individual/dashboard');
        } else {
          console.log('No user profile found, showing signup form');
          setFormData(prev => ({
            ...prev,
            personalInfo: {
              ...prev.personalInfo,
              email: user.email || ""
            }
          }));
          setShowSignupForm(true);
        }
      } catch (err) {
        console.error('Error in checkAuth:', err);
        setError(err);
      }
    };

    checkAuth();
  }, [user, navigate]);

  // Add more detailed loading states
  if (!user) {
    console.log('Rendering loading state (no user)');
    return (
      <div className="indiv-signup-loading">
        <div className="indiv-signup-loading-content">
          <div className="indiv-signup-loading-spinner"></div>
          <div className="indiv-signup-loading-text">Loading user data...</div>
        </div>
      </div>
    );
  }

  if (error) {
    console.log('Rendering error state:', error);
    return (
      <div className="indiv-signup-error">
        <h2>Error</h2>
        <p>{error.message}</p>
        <button onClick={() => window.location.reload()}>Try Again</button>
      </div>
    );
  }

  if (!showSignupForm) {
    console.log('Rendering loading state (waiting for form)');
    return (
      <div className="indiv-signup-loading">
        <div className="indiv-signup-loading-content">
          <div className="indiv-signup-loading-spinner"></div>
          <div className="indiv-signup-loading-text">Loading signup form...</div>
        </div>
      </div>
    );
  }

  console.log('Rendering full form');

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    
    // Handle deeply nested objects
    const keys = name.split('.');
    if (keys.length > 1) {
      setFormData(prev => {
        let newData = { ...prev };
        let current = newData;
        for (let i = 0; i < keys.length - 1; i++) {
          current[keys[i]] = { ...current[keys[i]] };
          current = current[keys[i]];
        }
        current[keys[keys.length - 1]] = value;
        return newData;
      });
    } else {
      setFormData(prev => ({ ...prev, [name]: value }));
    }
  };

  const handleStepClick = (stepNumber) => {
    if (completedSteps.includes(stepNumber) || stepNumber === currentStep) {
      setCurrentStep(stepNumber);
    }
  };

  const handleSubmit = async () => {
    try {
      setIsSubmitting(true);
      setError(null);

      // Debug log to check form data
      console.log('Submitting form data:', formData);

      // Validate salary before submission
      const formattedData = {
        ...formData,
        employmentInfo: {
          ...formData.employmentInfo,
          salary: formData.employmentInfo.salary ? 
            parseFloat(formData.employmentInfo.salary) : null
        }
      };

      console.log('Formatted data before submission:', formattedData);

      // Pass the formatted data
      const profileResult = await supabaseService.createIndividualProfile(formattedData);

      if (profileResult.error) {
        console.error('Profile creation error:', profileResult.error);
        throw new Error(`Failed to create user profile: ${profileResult.error.message}`);
      }

      // Update HSA eligibility
      const eligibilityResult = await supabaseService.updateHSAEligibility(user.id, formData.hsaEligibility);
      
      if (eligibilityResult.error) {
        console.error('HSA eligibility update error:', eligibilityResult.error);
        throw new Error(`Failed to update HSA eligibility: ${eligibilityResult.error.message}`);
      }

      navigate('/individual/dashboard');
    } catch (error) {
      console.error('Submission error:', error);
      console.error('Detailed error information:', error);
      setError(error.message);
    } finally {
      setIsSubmitting(false);
    }
  };

  const handleNextStep = () => {
    console.log('Next step clicked. Current step:', currentStep);
    
    if (currentStep === 3) {
      console.log('On final step - triggering submit');
      handleSubmit();
    } else {
      console.log('Advancing to next step');
      if (!completedSteps.includes(currentStep)) {
        setCompletedSteps([...completedSteps, currentStep]);
      }
      setCurrentStep((prevStep) => prevStep + 1);
    }
  };

  const renderFormStep = () => {
    switch (currentStep) {
      case 1:
        return (
          <>
            <div className="indiv-signup-section-title">Tell us about yourself</div>
            <div className="indiv-signup-section-description">We'll use this information to confirm your identity</div>
            
            <div className="indiv-signup-form-group">
              <div className="indiv-signup-form-group-header">General Information</div>
              <div className="indiv-signup-input-row">
                <div className="indiv-signup-input-field third-width">
                  <FormInput
                    name="personalInfo.firstName"
                    text="First Name"
                    value={formData.personalInfo.firstName}
                    onChange={handleInputChange}
                  />
                </div>
                <div className="indiv-signup-input-field third-width">
                  <FormInput
                    name="personalInfo.middleName"
                    text="Middle Name (Optional)"
                    value={formData.personalInfo.middleName}
                    onChange={handleInputChange}
                  />
                </div>
                <div className="indiv-signup-input-field third-width">
                  <FormInput
                    name="personalInfo.lastName"
                    text="Last Name"
                    value={formData.personalInfo.lastName}
                    onChange={handleInputChange}
                  />
                </div>
              </div>
              <div className="indiv-signup-input-row">
                <div className="indiv-signup-input-field half-width">
                  <FormInput
                    name="personalInfo.dateOfBirth"
                    text="Date of Birth"
                    value={formData.personalInfo.dateOfBirth}
                    onChange={handleInputChange}
                    type="date"
                  />
                </div>
                <div className="indiv-signup-input-field half-width">
                  <FormInput
                    name="personalInfo.phoneNumber"
                    text="Phone Number"
                    value={formData.personalInfo.phoneNumber}
                    onChange={handleInputChange}
                  />
                </div>
              </div>
            </div>

            <div className="indiv-signup-form-group">
              <div className="indiv-signup-form-group-header">Social Security Number</div>
              <div className="indiv-signup-input-field">
                <FormInput
                  name="personalInfo.ssn"
                  text="Social Security Number"
                  value={formData.personalInfo.ssn}
                  onChange={handleInputChange}
                />
              </div>
              <div className="indiv-signup-input-field">
                <FormInput
                  name="personalInfo.confirmSsn"
                  text="Confirm Social Security Number"
                  value={formData.personalInfo.confirmSsn}
                  onChange={handleInputChange}
                />
              </div>
            </div>

            <div className="indiv-signup-form-group">
              <div className="indiv-signup-form-group-header">Address</div>
              <div className="indiv-signup-input-row">
                <div className="indiv-signup-input-field two-thirds-width">
                  <FormInput
                    name="contactInfo.address.streetAddress"
                    text="Home Address"
                    value={formData.contactInfo.address.streetAddress}
                    onChange={handleInputChange}
                  />
                </div>
                <div className="indiv-signup-input-field third-width">
                  <FormInput
                    name="contactInfo.address.aptUnit"
                    text="Apt no., unit, suite"
                    value={formData.contactInfo.address.aptUnit}
                    onChange={handleInputChange}
                  />
                </div>
              </div>
              <div className="indiv-signup-input-row">
                <div className="indiv-signup-input-field third-width">
                  <FormInput
                    name="contactInfo.address.city"
                    text="City"
                    value={formData.contactInfo.address.city}
                    onChange={handleInputChange}
                  />
                </div>
                <div className="indiv-signup-input-field third-width">
                  <FormInput
                    name="contactInfo.address.state"
                    text="State"
                    value={formData.contactInfo.address.state}
                    onChange={handleInputChange}
                  />
                </div>
                <div className="indiv-signup-input-field third-width">
                  <FormInput
                    name="contactInfo.address.zipCode"
                    text="Zip Code"
                    value={formData.contactInfo.address.zipCode}
                    onChange={handleInputChange}
                  />
                </div>
              </div>
            </div>
          </>
        );
      case 2:
        return (
          <>
            <div className="indiv-signup-section-title">Employment Details</div>
            <div className="indiv-signup-section-description">Tell us about your current employment</div>
            
            <div className="indiv-signup-form-group">
              <div className="indiv-signup-form-group-header">Work Information</div>
              <div className="indiv-signup-input-row">
                <div className="indiv-signup-input-field half-width">
                  <FormInput
                    name="employmentInfo.jobTitle"
                    text="Job Title"
                    value={formData.employmentInfo?.jobTitle || ""}
                    onChange={handleInputChange}
                  />
                </div>
                <div className="indiv-signup-input-field half-width">
                  <FormInput
                    name="employmentInfo.department"
                    text="Department"
                    value={formData.employmentInfo?.department || ""}
                    onChange={handleInputChange}
                  />
                </div>
              </div>
              <div className="indiv-signup-input-field">
                <FormInput
                  name="employmentInfo.companyName"
                  text="Company Name"
                  value={formData.employmentInfo?.companyName || ""}
                  onChange={handleInputChange}
                />
              </div>
              <div className="indiv-signup-input-row">
                <div className="indiv-signup-input-field half-width">
                  <FormInput
                    name="employmentInfo.workEmail"
                    text="Work Email"
                    value={formData.employmentInfo?.workEmail || ""}
                    onChange={handleInputChange}
                  />
                </div>
                <div className="indiv-signup-input-field half-width">
                  <FormInput
                    name="employmentInfo.workPhone"
                    text="Work Phone"
                    value={formData.employmentInfo?.workPhone || ""}
                    onChange={handleInputChange}
                  />
                </div>
              </div>
            </div>
          </>
        );
      case 3:
        return (
          <>
            <div className="indiv-signup-section-title">Health Insurance Information</div>
            <div className="indiv-signup-section-description">Please answer the following questions about your health coverage</div>
            
            <div className="indiv-signup-form-group health-questions">
              <div className="indiv-signup-form-group-header">HSA Eligibility Questions</div>
              
              <RadioQuestion
                question="Are you a permanent resident of the United States?"
                name="hsaEligibility.isEligibleResident"
                value={formData.hsaEligibility.isEligibleResident}
                onChange={handleInputChange}
              />

              <RadioQuestion
                question="Are you currently enrolled in a high-deductible health plan (HDHP)?"
                name="hsaEligibility.hasHDHP"
                value={formData.hsaEligibility.hasHDHP}
                onChange={handleInputChange}
              />

              <RadioQuestion
                question="Are you covered by any other non-HDHP health insurance plan (e.g., Medicare, Medicaid, or a spouse's plan)?"
                name="hsaEligibility.hasOtherCoverage"
                value={formData.hsaEligibility.hasOtherCoverage}
                onChange={handleInputChange}
              />

              <RadioQuestion
                question="Are you currently enrolled in Medicare Part A, Part B, or any other part of Medicare?"
                name="hsaEligibility.hasMedicare"
                value={formData.hsaEligibility.hasMedicare}
                onChange={handleInputChange}
              />

              <RadioQuestion
                question="Do you have access to or are you covered by a Health Reimbursement Arrangement (HRA) or a Flexible Spending Account (FSA)?"
                name="hsaEligibility.hasHraFsa"
                value={formData.hsaEligibility.hasHraFsa}
                onChange={handleInputChange}
              />

              <RadioQuestion
                question="Are you claimed as a dependent on someone else's tax return?"
                name="hsaEligibility.isDependent"
                value={formData.hsaEligibility.isDependent}
                onChange={handleInputChange}
              />

              <RadioQuestion
                question="Are you a U.S. taxpayer eligible to file income tax returns?"
                name="hsaEligibility.isUSTaxpayer"
                value={formData.hsaEligibility.isUSTaxpayer}
                onChange={handleInputChange}
              />

              <RadioQuestion
                question="Do you have any Veterans Affairs (VA) benefits that cover medical expenses? If yes, have you received care under those benefits in the past 3 months?"
                name="hsaEligibility.hasVABenefits"
                value={formData.hsaEligibility.hasVABenefits}
                onChange={handleInputChange}
              />

              <RadioQuestion
                question="Are you currently covered by TRICARE or any military health benefits?"
                name="hsaEligibility.hasTRICARE"
                value={formData.hsaEligibility.hasTRICARE}
                onChange={handleInputChange}
              />
            </div>
          </>
        );
      default:
        return null;
    }
  };

  return (
    <div className="indiv-signup-container">
      <div className="indiv-signup-sidebar">
        <div className="indiv-signup-logo-section">
          <div className="indiv-signup-logo-wrapper">
            <div className="indiv-signup-logo-inner">
              <div className="indiv-signup-logo-icon">
                <svg 
                  width="100%" 
                  height="100%" 
                  viewBox="0 0 72 72" 
                  fill="none" 
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path d="M11.7941 53.1078L9.70167 49.0548C1.70189 51.1739 0 58.6965 0 65.6046H4.25471C4.25471 57.0248 7.65849 54.5173 11.7941 53.1078Z" fill="currentColor"/>
                  <path d="M11.7941 53.1078C16.5889 60.7079 25.1765 65.6053 34.8824 65.6047C38.2851 65.6044 41.6878 64.9947 44.7811 63.7756C61.021 58.4416 72.0014 42.1377 72 22.6349C71.9997 17.9565 70.5453 12.953 68.8253 8.44065C60.6092 35.5907 44.2713 46.7064 11.7941 53.1078Z" fill="currentColor"/>
                  <path d="M64.5517 7C59.9365 11.0477 51.649 13.342 46.1694 13.3424C44.4681 13.3425 42.7667 13.1903 41.0653 12.7333C39.0545 12.2764 36.8891 11.9718 34.8784 11.9719C19.7208 11.973 7.65739 24.0107 7.65849 38.9426C7.69787 43.5024 8.32254 45.8666 9.70167 49.0548C39.402 42.7235 56.4122 31.8769 64.5517 7Z" fill="currentColor"/>
                </svg>
              </div>
            </div>
          </div>
        </div>
        
        <div className="indiv-signup-nav-menu">
          <NavItem 
            text="Personal Details" 
            stepNumber={1}
            isActive={currentStep === 1}
            isCompleted={completedSteps.includes(1)}
            onClick={() => handleStepClick(1)}
          />
          <NavItem 
            text="Employment Details" 
            stepNumber={2}
            isActive={currentStep === 2}
            isCompleted={completedSteps.includes(2)}
            onClick={() => handleStepClick(2)}
          />
          <NavItem 
            text="Health Plan Details" 
            stepNumber={3}
            isActive={currentStep === 3}
            isCompleted={completedSteps.includes(3)}
            onClick={() => handleStepClick(3)}
          />
        </div>
        
        <div className="indiv-signup-signout-section">
          <div 
            className="indiv-signup-signout-wrapper"
            onClick={() => signOut()}
            style={{ cursor: 'pointer' }}
          >
            <div className="indiv-signup-signout-content">
              <div className="indiv-signup-signout-icon">
                <svg 
                  width="100%" 
                  height="100%" 
                  viewBox="0 0 12 12" 
                  fill="none" 
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path 
                    d="M4.5 10.5H2.5C2.23478 10.5 1.98043 10.3946 1.79289 10.2071C1.60536 10.0196 1.5 9.76522 1.5 9.5V2.5C1.5 2.23478 1.60536 1.98043 1.79289 1.79289C1.98043 1.60536 2.23478 1.5 2.5 1.5H4.5" 
                    stroke="currentColor" 
                    strokeLinecap="round" 
                    strokeLinejoin="round"
                  />
                  <path 
                    d="M8 8.5L10.5 6L8 3.5" 
                    stroke="currentColor" 
                    strokeLinecap="round" 
                    strokeLinejoin="round"
                  />
                  <path 
                    d="M10.5 6H4.5" 
                    stroke="currentColor" 
                    strokeLinecap="round" 
                    strokeLinejoin="round"
                  />
                </svg>
              </div>
              <div className="indiv-signup-signout-text">Sign out</div>
            </div>
          </div>
        </div>
      </div>

      <div className="indiv-signup-scrollable-content">
        <div className="indiv-signup-main-content">
          <div className="indiv-signup-form-section">
            {renderFormStep()}
          </div>
          <div className="indiv-signup-button-container">
            <button 
              className="indiv-signup-submit-button" 
              onClick={handleNextStep}
              disabled={isSubmitting}
            >
              {isSubmitting ? 'Creating Account...' : 'Save & Continue'}
            </button>
          </div>
        </div>
        <div className="indiv-signup-preview-section">
          <div className="indiv-signup-preview-container">
            <img 
              src={require('../assets/images/signup-preview-img.webp')} 
              alt="Customer testimonial" 
              className="indiv-signup-preview-image"
            />
            <div className="indiv-signup-preview-quote">
              My wealth has come from a combination of living in America, some lucky genes, and compound interest.
            </div>
            <div className="indiv-signup-preview-author">
              <div className="indiv-signup-preview-name">Warren Buffett</div>
              <div className="indiv-signup-preview-title">CEO</div>
              <div className="indiv-signup-preview-company">Berkshire Hathaway</div>
            </div>
          </div>
        </div>
      </div>
      
      {/* Add loading overlay when submitting */}
      {isSubmitting && (
        <div className="indiv-signup-overlay">
          <div className="indiv-signup-loading">
            <div className="indiv-signup-loading-content">
              <div className="indiv-signup-loading-spinner"></div>
              <div className="indiv-signup-loading-text">
                Creating your HSA account...
                <br />
                <small>Connecting to financial services...</small>
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
}

function NavItem({ text, stepNumber, isActive, isCompleted, onClick }) {
  return (
    <div 
      className={`indiv-signup-nav-item ${isActive ? "active" : ""} ${isCompleted ? "completed" : ""}`}
      onClick={onClick}
      style={{ cursor: isCompleted || isActive ? 'pointer' : 'default' }}
    >
      <div className="indiv-signup-nav-item-inner">
        <div className="indiv-signup-nav-icon">
          <div className="indiv-signup-nav-icon-box">
            <svg className="checked-icon" width="12" height="12" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
              <g clipPath="url(#clip0_403_717)">
                <path fillRule="evenodd" clipRule="evenodd" d="M5.49752 0.0867896L5.36027 0.0965396C4.02378 0.201507 2.76859 0.779921 1.8205 1.72774C0.872407 2.67556 0.293628 3.93057 0.188273 5.26704C0.175523 5.40504 0.175523 6.01479 0.188273 6.15279C0.24426 6.87304 0.439629 7.57555 0.763524 8.22129C1.03333 8.76598 1.39084 9.2626 1.82177 9.69129C2.44075 10.3111 3.19575 10.7779 4.02677 11.0548C4.45903 11.1972 4.90666 11.2878 5.36027 11.3248C5.49902 11.3368 6.10877 11.3368 6.24677 11.3248C6.96707 11.2686 7.66959 11.0729 8.31527 10.7488C8.9936 10.4116 9.5967 9.94064 10.0883 9.36429C10.5591 8.81205 10.9193 8.17449 11.1495 7.48629C11.292 7.05404 11.3826 6.60641 11.4195 6.15279C11.4315 6.01479 11.4315 5.40504 11.4195 5.26704C11.3635 4.54679 11.1682 3.84428 10.8443 3.19854C10.5067 2.52043 10.0356 1.91759 9.45902 1.42629C8.85153 0.908708 8.14152 0.525334 7.37552 0.30129C7.02277 0.199647 6.66114 0.131841 6.29552 0.0987896C6.02972 0.0863769 5.76358 0.082625 5.49752 0.0875396V0.0867896ZM8.71727 3.26829C8.78252 3.29079 8.81627 3.31104 8.86127 3.35604C8.89612 3.38838 8.9232 3.42819 8.94047 3.47248C8.95775 3.51677 8.96477 3.5644 8.96102 3.61179C8.96135 3.67675 8.942 3.74029 8.90552 3.79404C8.87552 3.83904 4.98827 7.72629 4.94252 7.75554C4.88622 7.79599 4.81738 7.81513 4.74827 7.80954C4.67917 7.81513 4.61033 7.79599 4.55402 7.75554C3.92874 7.14638 3.31143 6.52908 2.70227 5.90379C2.6659 5.85027 2.64656 5.787 2.64677 5.72229C2.64302 5.6749 2.65005 5.62727 2.66732 5.58298C2.6846 5.53868 2.71168 5.49888 2.74652 5.46654C2.77878 5.43179 2.81847 5.40478 2.86262 5.38751C2.90677 5.37024 2.95425 5.36315 3.00152 5.36679C3.07652 5.36979 3.12602 5.38479 3.18002 5.42079C3.19652 5.43204 3.55652 5.78754 3.97952 6.21054L4.74752 6.97929L6.57152 5.15679C7.18414 4.54291 7.79814 3.9304 8.41352 3.31929C8.45716 3.28901 8.50695 3.26873 8.55932 3.25992C8.6117 3.2511 8.66538 3.25396 8.71652 3.26829H8.71727Z" fill="currentColor"/>
              </g>
            </svg>
            <svg className="unchecked-icon" width="12" height="12" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
              <g clipPath="url(#clip0_373_626)">
                <path d="M6 10.875C8.69239 10.875 10.875 8.69239 10.875 6C10.875 3.30761 8.69239 1.125 6 1.125C3.30761 1.125 1.125 3.30761 1.125 6C1.125 8.69239 3.30761 10.875 6 10.875Z" stroke="currentColor" strokeWidth="0.75"/>
              </g>
            </svg>
          </div>
        </div>
        <div className="indiv-signup-nav-text">{text}</div>
      </div>
    </div>
  );
}

function FormInput({ text, name, value, onChange, type = "text" }) {
  const [isFocused, setIsFocused] = useState(false);
  const [showDatePicker, setShowDatePicker] = useState(false);

  // Add US states array
  const states = [
    'AL', 'AK', 'AZ', 'AR', 'CA', 'CO', 'CT', 'DE', 'FL', 'GA',
    'HI', 'ID', 'IL', 'IN', 'IA', 'KS', 'KY', 'LA', 'ME', 'MD',
    'MA', 'MI', 'MN', 'MS', 'MO', 'MT', 'NE', 'NV', 'NH', 'NJ',
    'NM', 'NY', 'NC', 'ND', 'OH', 'OK', 'OR', 'PA', 'RI', 'SC',
    'SD', 'TN', 'TX', 'UT', 'VT', 'VA', 'WA', 'WV', 'WI', 'WY'
  ];

  // Handle state dropdown
  if (name === "contactInfo.address.state") {
    return (
      <div className="indiv-signup-input-field">
        <div className="indiv-signup-input-wrapper">
          <div className="indiv-signup-input-container">
            <select
              className="indiv-signup-input"
              name={name}
              value={value}
              onChange={onChange}
              onFocus={() => setIsFocused(true)}
              onBlur={() => setIsFocused(false)}
            >
              <option value="">Select State</option>
              {states.map((state) => (
                <option key={state} value={state}>
                  {state}
                </option>
              ))}
            </select>
            <label 
              className={`indiv-signup-input-label ${(isFocused || value) ? 'indiv-signup-input-label-float' : ''}`}
            >
              {text}
            </label>
          </div>
        </div>
      </div>
    );
  }

  // Handle date picker
  if (name === "personalInfo.dateOfBirth") {
    const isValidDate = (month, day, year) => {
      const m = parseInt(month, 10);
      const d = parseInt(day, 10);
      const y = parseInt(year, 10);
      
      if (m < 1 || m > 12) return false;
      if (d < 1 || d > 31) return false;
      if (y < 1900) return false;
      
      const daysInMonth = new Date(y, m, 0).getDate();
      if (d > daysInMonth) return false;
      
      return true;
    };

    const handleDateInput = (e) => {
      const input = e.target.value.replace(/\D/g, '');
      if (input.length > 8) return;
      
      let formattedDate = '';
      const currentYear = new Date().getFullYear();
      
      if (input.length >= 1) {
        const month = input.substring(0, 2);
        if (month.length === 2 && parseInt(month) > 12) return;
        formattedDate += month;
      }
      if (input.length >= 3) {
        const month = input.substring(0, 2);
        const day = input.substring(2, 4);
        if (day.length === 2 && parseInt(day) > 31) return;
        formattedDate += '/' + day;
      }
      if (input.length >= 5) {
        const month = input.substring(0, 2);
        const day = input.substring(2, 4);
        let year = input.substring(4, 8);
        
        if (year.length === 4) {
          const inputYear = parseInt(year, 10);
          if (inputYear > currentYear) {
            year = currentYear.toString();
          }
          if (!isValidDate(month, day, year)) return;
        }
        formattedDate += '/' + year;
      }
      
      onChange({
        target: {
          name,
          value: formattedDate
        }
      });
    };

    const handleCalendarSelect = (date) => {
      const month = (date.getMonth() + 1).toString().padStart(2, '0');
      const day = date.getDate().toString().padStart(2, '0');
      const year = date.getFullYear();
      
      onChange({
        target: {
          name,
          value: `${month}/${day}/${year}`
        }
      });
      setShowDatePicker(false);
    };

    return (
      <div className="indiv-signup-input-field">
        <div className={`indiv-signup-input-wrapper ${isFocused ? 'focused' : ''}`}>
          <div className="indiv-signup-input-container">
            <input
              className="indiv-signup-input"
              value={value || ''}
              onChange={handleDateInput}
              onFocus={() => setIsFocused(true)}
              onBlur={() => {
                if (!value) setIsFocused(false);
              }}
              placeholder={isFocused ? "MM/DD/YYYY" : ""}
              maxLength={10}
              autoComplete="off"
            />
            <div 
              className="calendar-icon" 
              onClick={() => setShowDatePicker(!showDatePicker)}
            >
              📅
            </div>
            {showDatePicker && (
              <div className="datepicker-popup">
                <DatePicker
                  inline
                  selected={value ? new Date(value.split('/').reverse().join('-')) : null}
                  onChange={handleCalendarSelect}
                  dateFormat="MM/dd/yyyy"
                  showMonthDropdown
                  showYearDropdown
                  dropdownMode="select"
                  maxDate={new Date()}
                  yearDropdownItemNumber={100}
                  calendarClassName="custom-datepicker-calendar"
                />
              </div>
            )}
            <label 
              className={`indiv-signup-input-label ${(isFocused || value) ? 'indiv-signup-input-label-float' : ''}`}
            >
              {text}
            </label>
          </div>
        </div>
      </div>
    );
  }

  // Rest of the component remains the same...
  return (
    <div className="indiv-signup-input-field">
      <div className="indiv-signup-input-wrapper">
        <div className="indiv-signup-input-container">
          <input
            className="indiv-signup-input"
            type="text"
            name={name}
            value={value}
            onChange={onChange}
            placeholder=" "
            id={name}
          />
          <label className="indiv-signup-input-label" htmlFor={name}>
            {text}
          </label>
        </div>
      </div>
    </div>
  );
}

function RadioQuestion({ question, name, value, onChange }) {
  return (
    <div className="indiv-signup-radio-question">
      <div className="indiv-signup-question-text">{question}</div>
      <div className="indiv-signup-radio-options">
        <label className="indiv-signup-radio-label">
          <input
            type="radio"
            name={name}
            value="true"
            checked={value === true}
            onChange={(e) => onChange({
              target: {
                name: name,
                value: e.target.value === "true"
              }
            })}
          />
          Yes
        </label>
        <label className="indiv-signup-radio-label">
          <input
            type="radio"
            name={name}
            value="false"
            checked={value === false}
            onChange={(e) => onChange({
              target: {
                name: name,
                value: e.target.value === "true"
              }
            })}
          />
          No
        </label>
      </div>
    </div>
  );
}

export default IndivSignup;