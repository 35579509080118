import React, { useState, useEffect } from 'react';
import { Routes, Route } from 'react-router-dom';
import EmployerSidebar from '../layouts/employer-sidebar';
import UnderMaintenance from '../static-components/under-maint';
import ClosedEyeIcon from '../assets/icons/closed-eye-icon.svg';
import RightArrowIcon from '../assets/icons/right-arrow-icon.svg';
import './employer-dashboard.css';
import { useFinchConnect } from '@tryfinch/react-connect';
import { useAuth } from '../contexts/AuthContext';
import { supabaseService } from '../services/supabase-service';

function ConnectPayrollContent({ isFinchLoaded, open }) {
  return (
    <div className="connect-payroll-main">
      <div className="connect-payroll-main-header">
        <div className="connect-payroll-title">Connect your payroll</div>
      </div>
      <div className="connect-payroll-details">
        <div className="connect-payroll-detail-card">
          <button 
            onClick={() => open()}
            className="finch-connect-button"
          >
            Connect Payroll Provider
          </button>
        </div>
        <div className="connect-payroll-detail-description">
          To connect your payroll, please provide your payroll provider's details.
        </div>
      </div>
    </div>
  );
}

function DashboardContent({ businessProfile }) {
  return (
    <div className="employer-dashboard-content">
      <div className="employer-dashboard-header">
        <div className="employer-dashboard-welcome-text">
          Welcome, {businessProfile.business_name}
        </div>
      </div>
      {/* Rest of your dashboard content */}
    </div>
  );
}

function EmployerDashboard() {
  const { user } = useAuth();
  const [isSidebarExpanded, setIsSidebarExpanded] = useState(false);
  const [isFinchLoaded, setIsFinchLoaded] = useState(false);
  const [businessProfile, setBusinessProfile] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  console.log('EmployerDashboard initial render, user:', user);

  useEffect(() => {
    const fetchBusinessProfile = async () => {
      if (!user) {
        console.log('No user found in fetchBusinessProfile');
        return;
      }
      
      try {
        setLoading(true);
        console.log('Fetching business profile for user:', user.id);
        const { data, error } = await supabaseService.getBusinessProfile(user.id);
        console.log('Business profile response:', { data, error });
        
        if (error) throw error;
        setBusinessProfile(data);
        setError(null);
      } catch (err) {
        console.error('Error fetching business profile:', err);
        setError(err.message);
        setBusinessProfile(null);
      } finally {
        setLoading(false);
      }
    };

    fetchBusinessProfile();
  }, [user]);

  useEffect(() => {
    const script = document.createElement('script');
    script.src = 'https://connect.tryfinch.com/connect.js';
    script.async = true;
    
    script.onload = () => {
      setIsFinchLoaded(true);
    };
    
    document.body.appendChild(script);

    return () => {
      document.body.removeChild(script);
    };
  }, []);

  const { open } = useFinchConnect({
    clientId: '96af0e91-d67a-451b-81a0-1fa7294a1448',
    products: ['company', 'directory', 'individual', 'employment', 'payment', 'pay_statement'],
    sandbox: true,
    onSuccess: ({ code }) => {
      console.log('Success! Authorization code:', code);
    },
    onError: ({ errorMessage }) => {
      console.error('Finch Connect error:', errorMessage);
    },
    onClose: () => {
      console.log('User closed Finch Connect');
    },
  });

  if (!user) {
    return <div className="employer-dashboard-message">Please log in to view your dashboard</div>;
  }

  if (loading) {
    return <div className="employer-dashboard-message">Loading...</div>;
  }

  if (error) {
    return <div className="employer-dashboard-error">Error: {error}</div>;
  }

  if (!businessProfile) {
    return <div className="employer-dashboard-message">No business profile found</div>;
  }

  return (
    <div className="employer-dashboard-wrapper">
      <div className="employer-dashboard-container">
        <EmployerSidebar
          isSidebarExpanded={isSidebarExpanded}
          setIsSidebarExpanded={setIsSidebarExpanded}
        />
        <Routes>
          <Route path="/" element={<DashboardContent businessProfile={businessProfile} />} />
          <Route path="/profile" element={<UnderMaintenance pageName="Profile" />} />
          <Route path="/wallet" element={<UnderMaintenance pageName="Wallet" />} />
          <Route path="/transfer" element={<UnderMaintenance pageName="Transfer" />} />
          <Route path="/pay" element={<UnderMaintenance pageName="Pay" />} />
          <Route path="/entities" element={<ConnectPayrollContent isFinchLoaded={isFinchLoaded} open={open} />} />
          <Route path="/help" element={<UnderMaintenance pageName="Help Center" />} />
          <Route path="/docs" element={<UnderMaintenance pageName="Documentation" />} />
          <Route path="/dev" element={<UnderMaintenance pageName="Developer" />} />
        </Routes>
      </div>
    </div>
  );
}

export default EmployerDashboard;
