import { createContext, useContext, useEffect, useState } from 'react'
import { supabase } from '../lib/supabase'
import { useNavigate } from 'react-router-dom'

console.log('AuthContext rendering');

const AuthContext = createContext({})

export function useAuth() {
  return useContext(AuthContext)
}

export function AuthProvider({ children }) {
  const [user, setUser] = useState(null)
  const [loading, setLoading] = useState(true)
  const navigate = useNavigate()

  useEffect(() => {
    console.log('AuthProvider effect running');
    
    const getInitialSession = async () => {
      try {
        const { data: { session }, error } = await supabase.auth.getSession();
        console.log('Initial session:', session);
        
        if (error || !session) {
          setUser(null);
          setLoading(false);
          return;
        }
        
        setUser(session.user);
        setLoading(false);
      } catch (error) {
        console.error('Session fetch error:', error);
        setUser(null);
        setLoading(false);
      }
    };
    
    getInitialSession();

    const unsubscribe = supabase.auth.onAuthStateChange(async (event, session) => {
      console.log('Auth state change:', event, session);
      
      if (event === 'SIGNED_IN' && session?.user) {
        setUser(session.user);
        setLoading(false);
        // Let the LoginPage handle navigation
      } else if (event === 'SIGNED_OUT' || event === 'USER_DELETED') {
        setUser(null);
        setLoading(false);
        window.location.replace('/login');
      }
    });

    return () => {
      if (unsubscribe?.data?.subscription?.unsubscribe) {
        unsubscribe.data.subscription.unsubscribe();
      }
    };
  }, []);

  const signIn = async (email, password) => {
    console.log('Attempting sign in');
    const { data, error } = await supabase.auth.signInWithPassword({ 
      email, 
      password 
    })
    if (error) throw error
    return data
  }

  const signUp = async (email, password) => {
    const { data, error } = await supabase.auth.signUp({ 
      email, 
      password 
    })
    if (error) throw error
    return data
  }

  const signOut = async () => {
    try {
      console.log('Starting sign out process');
      
      // Clear Supabase session
      await supabase.auth.signOut();
      
      // Clear all storage
      localStorage.clear();
      sessionStorage.clear();
      
      // Clear React state
      setUser(null);
      setLoading(false);
      
      // Force reload to home page
      window.location.href = '/';
    } catch (error) {
      console.error('Sign out error:', error);
      // Force reload even if there's an error
      window.location.href = '/';
    }
  };

  return (
    <AuthContext.Provider value={{ user, loading, signIn, signUp, signOut }}>
      {children}
    </AuthContext.Provider>
  )
} 