import React, { useState } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { useAuth } from '../contexts/AuthContext';
import { ReactComponent as Logo } from '../assets/images/svalbard-logo.svg';
import './navbar.css';

const Navbar = () => {
  const { user, signOut } = useAuth();
  const navigate = useNavigate();
  const location = useLocation();
  const [isSigningOut, setIsSigningOut] = useState(false);

  const lightBackgroundRoutes = ['/resources', '/pricing', '/products'];
  const isDarkMode = lightBackgroundRoutes.includes(location.pathname);

  const navLinks = [
    { text: "Individual HSA", path: "/individual-hsa" },
    { text: "Products", path: "/products" },
    { text: "For Business", path: "/for-business" },
    { text: "Pricing", path: "/pricing" },
    { text: "Resources", path: "/resources" },
  ];

  const handleLogin = () => {
    navigate('/login');
  };

  const handleSignOut = async () => {
    if (isSigningOut) return; // Prevent multiple clicks
    
    try {
      setIsSigningOut(true);
      console.log('Navbar: Starting sign out');
      await signOut();
      console.log('Navbar: Sign out successful');
    } catch (error) {
      console.error('Navbar: Error during sign out:', error);
      alert('Failed to sign out. Please try again.');
    } finally {
      setIsSigningOut(false);
    }
  };

  return (
    <nav className={`navbar-container ${isDarkMode ? 'dark-mode' : 'light-mode'}`}>
      <div className="navbar-content">
        <div className="navbar-logo-container" onClick={() => navigate("/")}>
          <Logo className="navbar-logo" />
        </div>

        <div className="navbar-links">
          {navLinks.map(({ text, path }) => (
            <div
              key={text}
              className="navbar-link"
              onClick={() => navigate(path)}
            >
              {text}
            </div>
          ))}
        </div>

        <div className="navbar-auth">
          {!user ? (
            <div className="navbar-auth-buttons">
              <div className="navbar-signup" onClick={handleLogin}>
                <div className="navbar-signup-circle" />
                <div className="navbar-signup-text">Login / Sign Up</div>
              </div>
            </div>
          ) : (
            <div className="navbar-user-info">
              <div className="navbar-user-name">
                {user.email}
              </div>
              <button
                onClick={handleSignOut}
                className="navbar-logout"
                disabled={isSigningOut}
              >
                {isSigningOut ? 'Signing out...' : 'Log Out'}
              </button>
            </div>
          )}
        </div>
      </div>
    </nav>
  );
};

export default Navbar;