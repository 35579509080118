import React from "react";
import StaticPageLayout from "../layouts/static-page-layout";
import LandingHero from "../static-components/landing-hero";
import LandingSectionTwo from "../static-components/landing-sect-two";

function LandingPage() {
  console.log("LandingPage rendering"); // Add this logging
  
  return (
    <StaticPageLayout>
      <div>
        <LandingHero />
        <LandingSectionTwo />
      </div>
    </StaticPageLayout>
  );
}

export default LandingPage;