import React from "react";
import Navbar from "./navbar";

function StaticPageLayout({ children }) {
  return (
    <>
      <Navbar />
      {children}
    </>
  );
}

export default StaticPageLayout; 