import React from 'react';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { useAuth } from '../contexts/AuthContext';
import SidebarExpansionIcon from '../assets/icons/sidebar-expansion-icon.svg';
import SidebarHomeIcon from '../assets/icons/sidebar-home-icon.svg';
import UserIcon from '../assets/icons/user-icon.svg';
import WalletIcon from '../assets/icons/wallet-icon.svg';
import TransferIcon from '../assets/icons/transfer-icon.svg';
import PayIcon from '../assets/icons/pay-icon.svg';
import EntitiesIcon from '../assets/icons/entities-icon.svg';
import HelpIcon from '../assets/icons/help-icon.svg';
import DocsIcon from '../assets/icons/docs-icon.svg';
import DevIcon from '../assets/icons/dev-icon.svg';
import './employer-sidebar.css';

function EmployerSidebar({ isSidebarExpanded, setIsSidebarExpanded }) {
  const location = useLocation();
  const navigate = useNavigate();
  const { signOut } = useAuth();

  const handleSignOut = async () => {
    try {
      await signOut();
      navigate('/');
    } catch (error) {
      console.error('Error signing out:', error);
    }
  };

  const handleNavItemClick = () => {
    if (!isSidebarExpanded) {
      setIsSidebarExpanded(true);
    }
  };

  return (
    <div className={`employer-sidebar ${isSidebarExpanded ? 'expanded' : ''}`}>
      <div className="employer-logo-section">
        <div className="employer-logo-wrapper">
          <div className="employer-logo-inner">
            <svg width="100%" height="100%" viewBox="0 0 72 72" fill="none" xmlns="http://www.w3.org/2000/svg">
              {/* ... SVG paths ... */}
            </svg>
          </div>
        </div>
        {isSidebarExpanded && (
          <button 
            className="sidebar-toggle"
            onClick={() => setIsSidebarExpanded(false)}
          >
            <img src={SidebarExpansionIcon} alt="Toggle sidebar" />
          </button>
        )}
      </div>

      <div className="employer-nav-menu">
        <Link 
          to="/employer/dashboard"
          className={`employer-nav-item ${location.pathname === '/employer/dashboard' ? 'active' : ''}`}
          onClick={handleNavItemClick}
        >
          <div className="employer-nav-icon">
            <img src={SidebarHomeIcon} alt="Home" className="employer-nav-icon-circle" />
          </div>
          {isSidebarExpanded && <span className="employer-nav-label">Dashboard</span>}
        </Link>

        <Link 
          to="/employer/dashboard/profile"
          className={`employer-nav-item ${location.pathname === '/employer/dashboard/profile' ? 'active' : ''}`}
          onClick={handleNavItemClick}
        >
          <div className="employer-nav-icon">
            <img src={UserIcon} alt="Profile" className="employer-nav-icon-circle" />
          </div>
          {isSidebarExpanded && <span className="employer-nav-label">Profile</span>}
        </Link>

        <Link 
          to="/employer/dashboard/wallet"
          className={`employer-nav-item ${location.pathname === '/employer/dashboard/wallet' ? 'active' : ''}`}
          onClick={handleNavItemClick}
        >
          <div className="employer-nav-icon">
            <img src={WalletIcon} alt="Wallet" className="employer-nav-icon-circle" />
          </div>
          {isSidebarExpanded && <span className="employer-nav-label">Wallet</span>}
        </Link>

        <Link 
          to="/employer/dashboard/transfer"
          className={`employer-nav-item ${location.pathname === '/employer/dashboard/transfer' ? 'active' : ''}`}
          onClick={handleNavItemClick}
        >
          <div className="employer-nav-icon">
            <img src={TransferIcon} alt="Transfer" className="employer-nav-icon-circle" />
          </div>
          {isSidebarExpanded && <span className="employer-nav-label">Transfer</span>}
        </Link>

        <Link 
          to="/employer/dashboard/pay"
          className={`employer-nav-item ${location.pathname === '/employer/dashboard/pay' ? 'active' : ''}`}
          onClick={handleNavItemClick}
        >
          <div className="employer-nav-icon">
            <img src={PayIcon} alt="Pay" className="employer-nav-icon-circle" />
          </div>
          {isSidebarExpanded && <span className="employer-nav-label">Pay</span>}
        </Link>

        <Link 
          to="/employer/dashboard/entities"
          className={`employer-nav-item ${location.pathname === '/employer/dashboard/entities' ? 'active' : ''}`}
          onClick={handleNavItemClick}
        >
          <div className="employer-nav-icon">
            <img src={EntitiesIcon} alt="Entities" className="employer-nav-icon-circle" />
          </div>
          {isSidebarExpanded && <span className="employer-nav-label">Connect Payroll</span>}
        </Link>

        <Link 
          to="/employer/dashboard/help"
          className={`employer-nav-item ${location.pathname === '/employer/dashboard/help' ? 'active' : ''}`}
          onClick={handleNavItemClick}
        >
          <div className="employer-nav-icon">
            <img src={HelpIcon} alt="Help" className="employer-nav-icon-circle" />
          </div>
          {isSidebarExpanded && <span className="employer-nav-label">Help</span>}
        </Link>

        <Link 
          to="/employer/dashboard/docs"
          className={`employer-nav-item ${location.pathname === '/employer/dashboard/docs' ? 'active' : ''}`}
          onClick={handleNavItemClick}
        >
          <div className="employer-nav-icon">
            <img src={DocsIcon} alt="Docs" className="employer-nav-icon-circle" />
          </div>
          {isSidebarExpanded && <span className="employer-nav-label">Documentation</span>}
        </Link>

        <Link 
          to="/employer/dashboard/dev"
          className={`employer-nav-item ${location.pathname === '/employer/dashboard/dev' ? 'active' : ''}`}
          onClick={handleNavItemClick}
        >
          <div className="employer-nav-icon">
            <img src={DevIcon} alt="Dev" className="employer-nav-icon-circle" />
          </div>
          {isSidebarExpanded && <span className="employer-nav-label">Developer</span>}
        </Link>
      </div>

      <div className="employer-signout-section">
        <div 
          className="employer-signout-wrapper"
          onClick={handleSignOut}
          style={{ cursor: 'pointer' }}
        >
          <div className="employer-signout-content">
            <div className="employer-signout-icon">
              <svg width="100%" height="100%" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                {/* ... SVG paths ... */}
              </svg>
            </div>
            {isSidebarExpanded && <div className="employer-signout-text">Sign out</div>}
          </div>
        </div>
      </div>
    </div>
  );
}

export default EmployerSidebar;