import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useAuth } from '../contexts/AuthContext';
import './SignupSelector.css';
import { supabase } from '../lib/supabase';

function SignupSelector() {
  const navigate = useNavigate();
  const { user } = useAuth();
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    const checkAccountType = async () => {
      if (!user) return;

      const { data, error } = await supabase
        .from('account_types')
        .select('type')
        .eq('user_id', user.id)
        .single();

      if (data) {
        // User already has an account type, redirect them
        navigate(data.type === 'individual' ? '/signup/individual' : '/signup/employer');
      }
    };

    checkAccountType();
  }, [user, navigate]);

  const handleSelection = async (type) => {
    try {
      setIsLoading(true);

      // Save account type to database
      const { error } = await supabase
        .from('account_types')
        .insert([
          { user_id: user.id, type }
        ]);

      if (error) throw error;

      // Navigate to appropriate signup page
      navigate(type === 'individual' ? '/signup/individual' : '/signup/employer');
    } catch (err) {
      console.error('Error saving account type:', err);
      alert('There was an error. Please try again.');
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <div className="signup-selector-container">
      <div className="signup-selector-content">
        <h1>Welcome to Svalbard</h1>
        <p>Please select how you'd like to use our platform</p>
        
        <div className="signup-selector-options">
          <div 
            className="signup-selector-card"
            onClick={() => handleSelection('individual')}
          >
            <div className="signup-selector-icon">👤</div>
            <h2>Individual</h2>
            <p>I want to open an HSA account for myself</p>
          </div>

          <div 
            className="signup-selector-card"
            onClick={() => handleSelection('employer')}
          >
            <div className="signup-selector-icon">🏢</div>
            <h2>Employer</h2>
            <p>I want to provide HSA benefits to my employees</p>
          </div>
        </div>
      </div>
    </div>
  );
}

export default SignupSelector; 