import React from "react";
import "./under-maint.css";

const UnderMaintenance = ({ pageName }) => {
  return (
    <div className="maintenance-container">
      <div className="maintenance-content">
        <div className="maintenance-text-section">
          <div className="maintenance-text-container">
            <div className="maintenance-title">This page is still being built.</div>
            <div className="maintenance-description">
              Please check back later! In the meantime, click below to email the devs about it.
            </div>
          </div>
        </div>
        <div className="button-container">
            <div className="button-text">Bother a developer</div>
        </div>
      </div>
    </div>
  );
};

export default UnderMaintenance;
