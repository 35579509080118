import React, { useState, useEffect } from 'react';
import { Routes, Route, useNavigate } from 'react-router-dom';
import { useAuth } from '../contexts/AuthContext';
import { supabase } from '../lib/supabase';
import IndividualSidebar from '../layouts/indiv-sidebar';
import ClosedEyeIcon from '../assets/icons/closed-eye-icon.svg';
import RightArrowIcon from '../assets/icons/right-arrow-icon.svg';
import DropDownArrowIcon from '../assets/icons/dropdown-arrow-icon.svg';
import UserIcon from '../assets/icons/user-icon.svg';
import WalletIcon from '../assets/icons/wallet-icon.svg';
import TransferIcon from '../assets/icons/transfer-icon.svg';
import PayIcon from '../assets/icons/pay-icon.svg';
import EntitiesIcon from '../assets/icons/entities-icon.svg';
import HelpIcon from '../assets/icons/help-icon.svg';
import DocsIcon from '../assets/icons/docs-icon.svg';
import DevIcon from '../assets/icons/dev-icon.svg';
import UnderMaintenance from '../static-components/under-maint';
import './indiv-dashboard.css';

function DashboardContent() {
  const navigate = useNavigate();
  const { user } = useAuth();
  const [accountData, setAccountData] = useState(null);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    async function fetchAccountData() {
      try {
        if (user?.id) {
          const { data, error } = await supabase
            .from('indiv_profiles')
            .select('*')
            .eq('user_id', user.id)
            .single();

          if (error) throw error;
          console.log('Fetched user data:', data);
          setAccountData(data);
        }
      } catch (error) {
        console.error('Error fetching account data:', error);
      } finally {
        setLoading(false);
      }
    }

    fetchAccountData();
  }, [user]);

  // Get balance from hsaAccount instead of currentBalance
  const balance = accountData?.currentBalance || 0;
  const formattedBalance = balance.toLocaleString('en-US', {
    minimumFractionDigits: 2,
    maximumFractionDigits: 2
  });

  const initialBalance = accountData?.currentBalance || 0;
  const currentBalance = accountData?.hsaAccount?.balance || 0;
  const profit = currentBalance - initialBalance;
  const profitPercentage = initialBalance > 0 
    ? ((profit / initialBalance) * 100).toFixed(2)
    : 0;

  const formattedYTD = (accountData?.hsaAccount?.yearToDateContributions || 0).toLocaleString('en-US', {
    style: 'currency',
    currency: 'USD'
  });

  const formattedEmployerContrib = (accountData?.hsaAccount?.employerContributions || 0).toLocaleString('en-US', {
    style: 'currency',
    currency: 'USD'
  });

  const formattedPersonalContrib = (accountData?.hsaAccount?.personalContributions || 0).toLocaleString('en-US', {
    style: 'currency',
    currency: 'USD'
  });

  return (
    <div className="indiv-dashboard-content">
      <div className="indiv-dashboard-header">
        <div className="indiv-dashboard-welcome-text">Welcome to your vault</div>
      </div>
      <div className="indiv-dashboard-content-area">
        <div className="indiv-dashboard-balance-card">
          <div className="indiv-dashboard-balance-info">
            <div className="indiv-dashboard-balance-text">
              ${formattedBalance}
              <img src={ClosedEyeIcon} alt="Closed Eye" className="indiv-dashboard-balance-icon" />
            </div>
            <div className="indiv-dashboard-profit-text">
              {profit >= 0 ? '+' : ''}{profit.toLocaleString('en-US', {
                style: 'currency',
                currency: 'USD'
              })} ({profitPercentage}%) all time
            </div>
            <div className="contribution-details">
              <div className="contribution-item">
                <span>YTD Contributions:</span>
                <span>{formattedYTD}</span>
              </div>
              <div className="contribution-item">
                <span>Employer Contributions:</span>
                <span>{formattedEmployerContrib}</span>
              </div>
              <div className="contribution-item">
                <span>Personal Contributions:</span>
                <span>{formattedPersonalContrib}</span>
              </div>
            </div>
          </div>
        </div>
        <div className="indiv-dashboard-right-column">
          <div 
            className="indiv-dashboard-card clickable"
            onClick={() => navigate('/individual/dashboard/manage-contributions')}
          >
            <div className="indiv-dashboard-card-header">
              <div className="indiv-dashboard-card-title">Manage contributions</div>
              <img src={RightArrowIcon} alt="Right Arrow" className="indiv-dashboard-card-line" />
            </div>
            <div className="indiv-dashboard-progress-bar"></div>
          </div>
          <div className="indiv-dashboard-card">
            <div className="indiv-dashboard-card-header">
              <div className="indiv-dashboard-card-title">Manage investments</div>
              <img src={RightArrowIcon} alt="Right Arrow" className="indiv-dashboard-card-line" />
            </div>
            <div className="indiv-dashboard-progress-bar"></div>
          </div>
          <div className="indiv-dashboard-card">
            <div className="indiv-dashboard-card-header">
              <div className="indiv-dashboard-card-title">Submit an expense</div>
              <img src={RightArrowIcon} alt="Right Arrow" className="indiv-dashboard-card-line" />
            </div>
          </div>
          <div className="indiv-dashboard-card">
            <div className="indiv-dashboard-card-header">
              <div className="indiv-dashboard-card-title">Recent activity</div>
              <img src={RightArrowIcon} alt="Right Arrow" className="indiv-dashboard-card-line" />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

// ConnectEmployerContent component remains the same
function ConnectEmployerContent() {
  return (
    <div className="connect-employer-main">
      <div className="connect-employer-main-header">
        <div className="connect-employer-title">Connect your employer</div>
      </div>
      <div className="connect-employer-details">
        <div className="connect-employer-detail-card">
          <div className="connect-employer-detail-title">Your current employment details</div>
          <div className="connect-employer-detail-item">
            <div className="connect-employer-detail-label">Name of employer:</div>
            <div className="connect-employer-detail-value">Apple Inc.</div>
          </div>
          <div className="connect-employer-detail-item">
            <div className="connect-employer-detail-label">Work email</div>
            <div className="connect-employer-detail-value">john@apple.com</div>
          </div>
          <div className="connect-employer-detail-description">
            In order to connect your employer to Svalbard, you must help us get in contact with your employer's HR.
          </div>
        </div>
      </div>
    </div>
  );
}

function ManageContributionsContent() {
  const [isFromDropdownOpen, setIsFromDropdownOpen] = useState(false);
  const [contributionAmount, setContributionAmount] = useState('');
  const [selectedAccount, setSelectedAccount] = useState(null);
  const [accountData, setAccountData] = useState(null);
  const { user } = useAuth();
  const navigate = useNavigate();

  useEffect(() => {
    async function fetchAccountData() {
      try {
        if (user?.id) {
          const { data, error } = await supabase
            .from('indiv_profiles')
            .select('*')
            .eq('user_id', user.id)
            .single();

          if (error) throw error;
          setAccountData(data);
        }
      } catch (error) {
        console.error('Error fetching account data:', error);
      }
    }

    fetchAccountData();
  }, [user]);

  const ANNUAL_LIMIT = 4150;
  const currentBalance = accountData?.currentBalance || 0;
  const progressPercentage = Math.min((currentBalance / ANNUAL_LIMIT) * 100, 100);
  
  const formattedCurrentBalance = currentBalance.toLocaleString('en-US', {
    style: 'currency',
    currency: 'USD',
    minimumFractionDigits: 2,
    maximumFractionDigits: 2
  });

  const formattedAnnualLimit = ANNUAL_LIMIT.toLocaleString('en-US', {
    style: 'currency',
    currency: 'USD',
    minimumFractionDigits: 2,
    maximumFractionDigits: 2
  });

  const bankAccounts = [
    { id: 1, name: 'Chase Checking', lastFour: '4321' },
    { id: 2, name: 'Bank of America Savings', lastFour: '7890' },
    { id: 3, name: 'Wells Fargo Checking', lastFour: '2468' }
  ];

  const handleAmountChange = (e) => {
    const value = e.target.value.replace(/[^0-9.]/g, '');
    if (value.split('.').length > 2) return;
    setContributionAmount(value);
  };

  const handleContribution = async () => {
    try {
      if (!contributionAmount || isNaN(contributionAmount)) {
        alert('Please enter a valid amount');
        return;
      }

      const amount = parseFloat(contributionAmount);
      if (amount <= 0) {
        alert('Please enter an amount greater than 0');
        return;
      }

      const { error } = await supabase
        .from('indiv_profiles')
        .update({ 
          currentBalance: (currentBalance + amount),
          updated_at: new Date().toISOString()
        })
        .eq('user_id', user.id);

      if (error) throw error;

      alert('Contribution successful!');
      navigate('/individual/dashboard');
    } catch (error) {
      console.error('Error processing contribution:', error);
      alert('Failed to process contribution. Please try again.');
    }
  };

  return (
    <div className="indiv-dashboard-content">
      <div className="indiv-dashboard-header">
        <div className="indiv-dashboard-welcome-text">Manage Contributions</div>
      </div>
      <div className="indiv-dashboard-content-area">
        <div className="indiv-dashboard-right-column">
          <div className="indiv-dashboard-card">
            <div className="contribution-progress">
              <div className="progress-percentage">{progressPercentage.toFixed(0)}%</div>
              <div className="indiv-dashboard-progress-bar"></div>
              <div className="progress-limit">
                <span>Annual limit:</span>
                <span>{formattedCurrentBalance}/{formattedAnnualLimit}</span>
              </div>
            </div>
          </div>
          <div className="indiv-dashboard-card">
            <div className="indiv-dashboard-card-header">
              <div className="indiv-dashboard-card-title">Make a contribution</div>
            </div>
            <div className="contribution-form">
              <div className="contribution-input">
                <div 
                  className="dropdown-trigger"
                  onClick={() => setIsFromDropdownOpen(!isFromDropdownOpen)}
                >
                  <div className="input-label">
                    {selectedAccount 
                      ? `From: ${selectedAccount.name} (...${selectedAccount.lastFour})`
                      : 'From:'
                    }
                  </div>
                  <img 
                    src={DropDownArrowIcon} 
                    alt="Dropdown Arrow" 
                    className={`dropdown-arrow ${isFromDropdownOpen ? 'open' : ''}`}
                  />
                </div>
                {isFromDropdownOpen && (
                  <div className="dropdown-content">
                    {bankAccounts.map(account => (
                      <button 
                        key={account.id}
                        className="link-account-button"
                        onClick={() => {
                          setSelectedAccount(account);
                          setIsFromDropdownOpen(false);
                        }}
                      >
                        <span>{account.name} (...{account.lastFour})</span>
                      </button>
                    ))}
                    <button className="link-account-button">
                      <span>Link another account</span>
                      <span>+</span>
                    </button>
                  </div>
                )}
              </div>
              <div className="contribution-input">
                <div className="input-label">To: Your Personal HSA</div>
                <img src={DropDownArrowIcon} alt="Dropdown Arrow" className="dropdown-arrow" />
              </div>
              <div className="contribution-amount">
                <div className="amount-label">Amount:</div>
                <input
                  type="text"
                  value={contributionAmount}
                  onChange={handleAmountChange}
                  placeholder="0.00"
                  className="amount-value"
                  style={{
                    border: 'none',
                    background: 'transparent',
                    fontSize: '16px',
                    width: '100%',
                    outline: 'none',
                    fontFamily: 'TWK Lausanne 350'
                  }}
                />
              </div>
              <div className="contribution-actions">
                <button 
                  className="contribution-next-button"
                  onClick={handleContribution}
                >
                  Process contribution
                </button>
              </div>
            </div>
          </div>
          <div className="indiv-dashboard-card">
            <div className="indiv-dashboard-card-header">
              <div className="indiv-dashboard-card-title">Recent Activity</div>
              <div className="arrow-icon"></div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

function IndividualDashboard() {
  const [isSidebarExpanded, setIsSidebarExpanded] = useState(false);

  return (
    <div className="indiv-dashboard-container">
      <IndividualSidebar 
        isSidebarExpanded={isSidebarExpanded}
        setIsSidebarExpanded={setIsSidebarExpanded}
      />
      <Routes>
        <Route path="/" element={<DashboardContent />} />
        <Route path="/connect-employer" element={<ConnectEmployerContent />} />
        <Route path="/manage-contributions" element={<ManageContributionsContent />} />
        <Route path="/profile" element={<UnderMaintenance pageName="Profile" />} />
        <Route path="/wallet" element={<UnderMaintenance pageName="Wallet" />} />
        <Route path="/transfer" element={<UnderMaintenance pageName="Transfer" />} />
        <Route path="/pay" element={<UnderMaintenance pageName="Pay" />} />
        <Route path="/entities" element={<UnderMaintenance pageName="Entities" />} />
        <Route path="/help" element={<UnderMaintenance pageName="Help Center" />} />
        <Route path="/docs" element={<UnderMaintenance pageName="Documentation" />} />
        <Route path="/dev" element={<UnderMaintenance pageName="Developer" />} />
      </Routes>
    </div>
  );
}

export default IndividualDashboard;