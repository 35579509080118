import { supabase } from '../lib/supabase';

export const supabaseService = {
  // User Profile Management
  async createUserProfile(userData) {
    try {
      const { data, error } = await supabase
        .from('indiv_profiles')
        .insert([{
          user_id: userData.user_id,
          email: userData.email,
          first_name: userData.firstName,
          last_name: userData.lastName,
          created_at: new Date().toISOString(),
          updated_at: new Date().toISOString()
        }]);

      return { data, error };
    } catch (error) {
      console.error('Error creating user profile:', error);
      return { data: null, error };
    }
  },

  async getUserProfile(userId) {
    try {
      const { data, error } = await supabase
        .from('indiv_profiles')
        .select('*')
        .eq('user_id', userId)
        .maybeSingle();

      return { data, error };
    } catch (error) {
      console.error('Error fetching user profile:', error);
      return { data: null, error };
    }
  },

  async updateUserProfile(userId, updates) {
    try {
      const cleanUpdates = {
        ...updates,
        zip_code: updates.zip_code ? updates.zip_code.toString() : null,
        phone_number: updates.phone_number ? updates.phone_number.toString() : null,
        updated_at: new Date().toISOString()
      };

      const { data, error } = await supabase
        .from('indiv_profiles')
        .upsert([{
          user_id: userId,
          ...cleanUpdates
        }])
        .select()
        .single();

      return { data, error };
    } catch (error) {
      console.error('Error updating user profile:', error);
      return { data: null, error };
    }
  },

  // Individual Profile Management
  async createIndividualProfile(profileData) {
    try {
      const { data: { user }, error: userError } = await supabase.auth.getUser();
      if (userError) throw userError;
      if (!user) throw new Error('No authenticated user found');
  
      // Debug log
      console.log('Raw profile data:', profileData);
  
      const formattedData = {
        user_id: user.id,
        first_name: profileData.personalInfo.firstName,
        middle_name: profileData.personalInfo.middleName,
        last_name: profileData.personalInfo.lastName,
        date_of_birth: profileData.personalInfo.dateOfBirth,
        ssn: profileData.personalInfo.ssn,
        phone_number: profileData.personalInfo.phoneNumber,
        street_address: profileData.contactInfo.address.streetAddress,
        city: profileData.contactInfo.address.city,
        state: profileData.contactInfo.address.state,
        zip_code: profileData.contactInfo.address.zipCode,
        // Updated employment fields
        current_employer: profileData.employmentInfo.companyName,
        job_title: profileData.employmentInfo.jobTitle,
        department: profileData.employmentInfo.department,
        work_email: profileData.employmentInfo.workEmail,
        work_phone: profileData.employmentInfo.workPhone,
        created_at: new Date().toISOString(),
        updated_at: new Date().toISOString()
      };
  
      // Debug log
      console.log('Formatted data for database:', formattedData);
  
      const { data, error } = await supabase
        .from('indiv_profiles')
        .upsert([formattedData])
        .select()
        .maybeSingle();
  
      if (error) {
        console.error('Database error:', error);
        throw error;
      }
  
      return { data, error: null };
    } catch (error) {
      console.error('Error in createIndividualProfile:', error);
      return { data: null, error };
    }
  },

  // Business Profile Management
  async createBusinessProfile(businessData) {
    try {
      console.log('Creating business profile with data:', businessData); // Debug log

      // Use the user_id from the passed data instead of fetching again
      if (!businessData.user_id) {
        throw new Error('No user_id provided in business data');
      }

      const formattedData = {
        user_id: businessData.user_id,  // Use the passed user_id
        business_name: businessData.businessName,
        dba: businessData.dba,
        ein: businessData.ein,
        business_type: businessData.businessType,
        employee_count: parseInt(businessData.employeeCount),
        street_address: businessData.businessAddress,
        suite: businessData.suite,
        city: businessData.city,
        state: businessData.state,
        zip_code: businessData.zip,
        phone_number: businessData.businessPhone,
        referral_source: businessData.referralSource,
        registration_complete: true,
        created_at: new Date().toISOString(),
        updated_at: new Date().toISOString()
      };

      console.log('Formatted data for insert:', formattedData); // Debug log

      const { data, error } = await supabase
        .from('business_profiles')
        .insert([formattedData])
        .select()
        .single();

      console.log('Insert response:', { data, error }); // Debug log

      if (error) throw error;
      return { data, error: null };
    } catch (error) {
      console.error('Error creating business profile:', error);
      throw error;
    }
  },

  // HSA Eligibility Management
  async updateHSAEligibility(userId, eligibilityData) {
    try {
      const snakeCaseData = {
        has_hdhdp: eligibilityData.hasHDHP,
        has_other_coverage: eligibilityData.hasOtherCoverage,
        has_medicare: eligibilityData.hasMedicare,
        is_dependent: eligibilityData.isDependent,
        is_eligible_resident: eligibilityData.isEligibleResident,
        is_eligible: eligibilityData.isEligible,
        has_hra_fsa: eligibilityData.hasHraFsa,
        is_us_taxpayer: eligibilityData.isUSTaxpayer,
        has_va_benefits: eligibilityData.hasVABenefits,
        has_tricare: eligibilityData.hasTRICARE,
        updated_at: new Date().toISOString()
      };
  
      const { data, error } = await supabase
        .from('hsa_eligibility')
        .upsert([{
          user_id: userId,
          ...snakeCaseData
        }])
        .select()
        .single();
  
      return { data, error };
    } catch (error) {
      console.error('Error updating HSA eligibility:', error);
      return { data: null, error };
    }
  },

  async getIndividualProfile(userId) {
    try {
      const { data, error } = await supabase
        .from('indiv_profiles')
        .select('*')
        .eq('user_id', userId)
        .maybeSingle();

      if (error) {
        console.error('Error fetching individual profile:', error);
        throw error;
      }

      return { data, error: null };
    } catch (error) {
      console.error('Error in getIndividualProfile:', error);
      return { data: null, error };
    }
  },
  
  async getBusinessProfile(userId) {
    try {
      const { data, error } = await supabase
        .from('business_profiles')
        .select('*')
        .eq('user_id', userId)
        .maybeSingle();
  
      if (error) {
        console.error('Error fetching business profile:', error);
        throw error;
      }
  
      return { data, error: null };
    } catch (error) {
      console.error('Error in getBusinessProfile:', error);
      return { data: null, error };
    }
  }
};

export default supabaseService; 