import React from "react";
import { useNavigate } from "react-router-dom"; // Import useNavigate from React Router
import "./landing-sect-two.css";

// ... imports stay the same ...

function LandingSectionTwo() {
  const navigate = useNavigate();

  const handleGetStartedClick = () => {
    navigate("/login");
  };

  return (
    <div className="sect-two-landing-section">
      <div className="sect-two-content-wrapper">
        <div className="sect-two-text-container">
          <div className="sect-two-headline">
            <span className="sect-two-headline-start">Give your health savings</span>
            <span className="sect-two-headline-space"> </span>
            <span className="sect-two-headline-end">a place to grow</span>
          </div>
          <div className="sect-two-subheadline">
            Whether you're using your HSA to save for a rainy day, or just trying to make the most of it, we've got a brilliantly easy way to build your wealth.
          </div>
        </div>
        <div className="sect-two-cta-container">
          <div className="sect-two-cta-button" onClick={handleGetStartedClick}>
            <div className="sect-two-cta-text">Get Started Today</div>
            <div className="sect-two-cta-icon-wrapper">
              <svg width="8" height="8" viewBox="0 0 8 8" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M6.90031 6.57627V1.91525L0.828713 8L0 7.16949L6.07159 1.08475L1.42065 1.11864V0H7.99962V6.57627H6.90031Z" fill="white"/>
              </svg>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default LandingSectionTwo;