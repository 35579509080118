import React from "react";
import StaticPageLayout from "../layouts/static-page-layout";
import UnderMaintenance from "../static-components/under-maint";

const ProductsPage = () => {
  return (
    <StaticPageLayout>
      <UnderMaintenance pageName="Products" />
    </StaticPageLayout>
  );
};

export default ProductsPage;
