import React, { useState } from "react";
import { useNavigate } from "react-router-dom"; // Import useNavigate
import "./landing-hero.css";

function LandingHero() {
  const navigate = useNavigate(); // Initialize the navigate function
  // Add state for toggle
  const [isEmployer, setIsEmployer] = useState(true);

  // Content objects for each view
  const content = {
    employer: {
      title: "The HSA platform built for employees and employers.",
      subtitle: "Health Savings Accounts were created to help you save your money. Svalbard was created to help you grow it."
    },
    employee: {
      title: "The HSA that gets you paid.",
      subtitle: "Take control of your healthcare savings and watch your money grow with our employee-focused HSA platform."
    }
  };

  const handleButtonClick = () => {
    navigate("/signin"); // Navigate to the /signup page
  };

  return (
    <div className="landing-hero">
      <div className="hero-content">
        <div className="hero-header">
          <div className="site-toggle">
            <div className="toggle-switch-container">
              <div className="toggle-switch" onClick={() => setIsEmployer(!isEmployer)}>
                <span className={`toggle-label ${isEmployer ? 'active' : ''}`}>Employer</span>
                <span className={`toggle-label ${!isEmployer ? 'active' : ''}`}>Employee</span>
                <div className={`toggle-slider ${!isEmployer ? 'slide-right' : ''}`}></div>
              </div>
            </div>
          </div>
          <div className="hero-text-container">
            <div className="hero-title">
              {isEmployer ? content.employer.title : content.employee.title}
            </div>
            <div className="hero-subtitle">
              {isEmployer ? content.employer.subtitle : content.employee.subtitle}
            </div>
          </div>
        </div>
        <div className="cta-container">
          <div className="cta-button primary">
            <div className="button-text">Open an HSA</div>
            <div className="button-icon">
              <svg width="8" height="8" viewBox="0 0 8 8" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M6.90031 6.57627V1.91525L0.828713 8L0 7.16949L6.07159 1.08475L1.42065 1.11864V0H7.99962V6.57627H6.90031Z" fill="black"/>
              </svg>
            </div>
          </div>
          <div className="cta-button secondary">
            <div className="button-text">More info</div>
            <div className="button-icon">
              <svg width="8" height="8" viewBox="0 0 8 8" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M6.90031 6.57627V1.91525L0.828713 8L0 7.16949L6.07159 1.08475L1.42065 1.11864V0H7.99962V6.57627H6.90031Z" fill="white"/>
              </svg>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default LandingHero;