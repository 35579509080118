import React from 'react';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { useAuth } from '../contexts/AuthContext';
import CoinIcon from '../assets/icons/coin-icon.svg';
import SidebarExpansionIcon from '../assets/icons/sidebar-expansion-icon.svg';
import SidebarHomeIcon from '../assets/icons/sidebar-home-icon.svg';
import UserIcon from '../assets/icons/user-icon.svg';
import WalletIcon from '../assets/icons/wallet-icon.svg';
import TransferIcon from '../assets/icons/transfer-icon.svg';
import PayIcon from '../assets/icons/pay-icon.svg';
import EntitiesIcon from '../assets/icons/entities-icon.svg';
import HelpIcon from '../assets/icons/help-icon.svg';
import DocsIcon from '../assets/icons/docs-icon.svg';
import DevIcon from '../assets/icons/dev-icon.svg';
import './indiv-sidebar.css';

function IndividualSidebar({ isSidebarExpanded, setIsSidebarExpanded }) {
  const location = useLocation();
  const navigate = useNavigate();
  const { signOut } = useAuth();

  const handleSignOut = async () => {
    try {
      await signOut();
      navigate('/');
    } catch (error) {
      console.error('Error signing out:', error);
    }
  };

  const handleNavItemClick = () => {
    if (!isSidebarExpanded) {
      setIsSidebarExpanded(true);
    }
  };

  return (
    <div className={`individual-sidebar ${isSidebarExpanded ? 'expanded' : ''}`}>
      <div className="individual-logo-section">
        <div className="individual-logo-wrapper">
          <div className="individual-logo-inner">
            <svg 
              width="100%" 
              height="100%" 
              viewBox="0 0 72 72" 
              fill="none" 
              xmlns="http://www.w3.org/2000/svg"
              className="individual-logo-icon"
            >
              <path d="M11.7941 53.1078L9.70167 49.0548C1.70189 51.1739 0 58.6965 0 65.6046H4.25471C4.25471 57.0248 7.65849 54.5173 11.7941 53.1078Z" fill="currentColor"/>
              <path d="M11.7941 53.1078C16.5889 60.7079 25.1765 65.6053 34.8824 65.6047C38.2851 65.6044 41.6878 64.9947 44.7811 63.7756C61.021 58.4416 72.0014 42.1377 72 22.6349C71.9997 17.9565 70.5453 12.953 68.8253 8.44065C60.6092 35.5907 44.2713 46.7064 11.7941 53.1078Z" fill="currentColor"/>
              <path d="M64.5517 7C59.9365 11.0477 51.649 13.342 46.1694 13.3424C44.4681 13.3425 42.7667 13.1903 41.0653 12.7333C39.0545 12.2764 36.8891 11.9718 34.8784 11.9719C19.7208 11.973 7.65739 24.0107 7.65849 38.9426C7.69787 43.5024 8.32254 45.8666 9.70167 49.0548C39.402 42.7235 56.4122 31.8769 64.5517 7Z" fill="currentColor"/>
            </svg>
          </div>
        </div>
        {isSidebarExpanded && (
          <button 
            className="sidebar-toggle"
            onClick={() => setIsSidebarExpanded(false)}
          >
            <img 
              src={SidebarExpansionIcon} 
              alt="Toggle sidebar"
            />
          </button>
        )}
      </div>
      <div className="individual-nav-menu">
        <Link 
          to="/individual/dashboard"
          className={`individual-nav-item ${location.pathname === '/individual/dashboard' ? 'active' : ''}`}
          onClick={handleNavItemClick}
        >
          <div className="individual-nav-icon">
            <img src={SidebarHomeIcon} alt="Home" className="individual-nav-icon-circle" />
          </div>
          {isSidebarExpanded && <span className="individual-nav-label">Dashboard</span>}
        </Link>
        <Link 
          to="/individual/dashboard/profile"
          className={`individual-nav-item ${location.pathname === '/individual/dashboard/profile' ? 'active' : ''}`}
          onClick={handleNavItemClick}
        >
          <div className="individual-nav-icon">
            <img src={UserIcon} alt="Profile" className="individual-nav-icon-circle" />
          </div>
          {isSidebarExpanded && <span className="individual-nav-label">Profile</span>}
        </Link>
        <Link 
          to="/individual/dashboard/wallet"
          className={`individual-nav-item ${location.pathname === '/individual/dashboard/wallet' ? 'active' : ''}`}
          onClick={handleNavItemClick}
        >
          <div className="individual-nav-icon">
            <img src={WalletIcon} alt="Wallet" className="individual-nav-icon-circle" />
          </div>
          {isSidebarExpanded && <span className="individual-nav-label">Wallet</span>}
        </Link>
        <Link 
          to="/individual/dashboard/transfer"
          className={`individual-nav-item ${location.pathname === '/individual/dashboard/transfer' ? 'active' : ''}`}
          onClick={handleNavItemClick}
        >
          <div className="individual-nav-icon">
            <img src={TransferIcon} alt="Transfer" className="individual-nav-icon-circle" />
          </div>
          {isSidebarExpanded && <span className="individual-nav-label">Transfer</span>}
        </Link>
        <Link 
          to="/individual/dashboard/pay"
          className={`individual-nav-item ${location.pathname === '/individual/dashboard/pay' ? 'active' : ''}`}
          onClick={handleNavItemClick}
        >
          <div className="individual-nav-icon">
            <img src={PayIcon} alt="Pay" className="individual-nav-icon-circle" />
          </div>
          {isSidebarExpanded && <span className="individual-nav-label">Pay</span>}
        </Link>
        <Link 
          to="/individual/dashboard/entities"
          className={`individual-nav-item ${location.pathname === '/individual/dashboard/entities' ? 'active' : ''}`}
          onClick={handleNavItemClick}
        >
          <div className="individual-nav-icon">
            <img src={EntitiesIcon} alt="Entities" className="individual-nav-icon-circle" />
          </div>
          {isSidebarExpanded && <span className="individual-nav-label">Entities</span>}
        </Link>
        <Link 
          to="/individual/dashboard/help"
          className={`individual-nav-item ${location.pathname === '/individual/dashboard/help' ? 'active' : ''}`}
          onClick={handleNavItemClick}
        >
          <div className="individual-nav-icon">
            <img src={HelpIcon} alt="Help" className="individual-nav-icon-circle" />
          </div>
          {isSidebarExpanded && <span className="individual-nav-label">Help</span>}
        </Link>
        <Link 
          to="/individual/dashboard/docs"
          className={`individual-nav-item ${location.pathname === '/individual/dashboard/docs' ? 'active' : ''}`}
          onClick={handleNavItemClick}
        >
          <div className="individual-nav-icon">
            <img src={DocsIcon} alt="Docs" className="individual-nav-icon-circle" />
          </div>
          {isSidebarExpanded && <span className="individual-nav-label">Documentation</span>}
        </Link>
        <Link 
          to="/individual/dashboard/dev"
          className={`individual-nav-item ${location.pathname === '/individual/dashboard/dev' ? 'active' : ''}`}
          onClick={handleNavItemClick}
        >
          <div className="individual-nav-icon">
            <img src={DevIcon} alt="Dev" className="individual-nav-icon-circle" />
          </div>
          {isSidebarExpanded && <span className="individual-nav-label">Developer</span>}
        </Link>
      </div>
      <div className="individual-signout-section">
        <div 
          className="individual-signout-wrapper"
          onClick={handleSignOut}
          style={{ cursor: 'pointer' }}
        >
          <div className="individual-signout-content">
            <div className="individual-signout-icon">
              <svg 
                width="100%" 
                height="100%" 
                viewBox="0 0 12 12" 
                fill="none" 
                xmlns="http://www.w3.org/2000/svg"
              >
                <path 
                  d="M4.5 10.5H2.5C2.23478 10.5 1.98043 10.3946 1.79289 10.2071C1.60536 10.0196 1.5 9.76522 1.5 9.5V2.5C1.5 2.23478 1.60536 1.98043 1.79289 1.79289C1.98043 1.60536 2.23478 1.5 2.5 1.5H4.5" 
                  stroke="currentColor" 
                  strokeLinecap="round" 
                  strokeLinejoin="round"
                />
                <path 
                  d="M8 8.5L10.5 6L8 3.5" 
                  stroke="currentColor" 
                  strokeLinecap="round" 
                  strokeLinejoin="round"
                />
                <path 
                  d="M10.5 6H4.5" 
                  stroke="currentColor" 
                  strokeLinecap="round" 
                  strokeLinejoin="round"
                />
              </svg>
            </div>
            {isSidebarExpanded && <div className="individual-signout-text">Sign out</div>}
          </div>
        </div>
      </div>
    </div>
  );
}

export default IndividualSidebar;
